import styled, { css } from 'styled-components'
import { MdOutlinePhoto } from 'react-icons/md'
import { FaQuestion } from 'react-icons/fa'
import { RiArticleLine } from 'react-icons/ri'
import { getAlphaRgb } from 'components/layouts'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ProfileWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.media.phone} {
    align-items: center;
  }
`
export const AvatarContainer = styled.div`
  min-width: 110px;
  min-height: 110px;
  max-width: 110px;
  max-height: 110px;
  position: relative;
  margin-right: 16px;

  ${({ theme }) => theme.media.phone} {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
`
export const Avatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  cursor: pointer;

  ${({ theme }) => theme.media.phone} {
    font-size: 30px;
  }
`
export const ImgProfile = styled.div`
  height: 100%;
  width: 100%;
  ${({ src }) =>
    src &&
    css`
      background-image: ${`url(${src})`};
      background-size: cover;
      background-position: center;
    `};
`
export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Title = styled.div`
  ${({ theme }) => theme.font.h2}
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.phone} {
    font-size: 30px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Info = styled.div`
  display: flex;
  flex-direction: column;
`
export const InfoTitle = styled.div`
  ${({ theme }) => theme.font.body2}
`
export const InfoValue = styled.div`
  ${({ theme }) => theme.font.h3}
`
export const KarmaValue = styled(InfoValue)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.accentP};
  margin-bottom: 10px;
`
export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
  ${({ theme }) => theme.media.phone} {
    width: 100%;
    margin-left: 0;
    align-items: flex-start;
  }
`
export const StartPostButton = styled.div`
  width: 100%;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
`
export const PostOptions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;

  ${({ theme }) => theme.media.phone} {
    flex-direction: row;
  }
`

export const PhotoIcon = styled(MdOutlinePhoto)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.accentP};
  margin-right: 12px;
`
export const AMQIcon = styled(FaQuestion)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.accentP};
  margin-right: 12px;
  ${({ theme }) => theme.media.phone} {
    margin-right: 4px;
  }
`
export const ArticleIcon = styled(RiArticleLine)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.accentP};
  margin-right: 12px;
  ${({ theme }) => theme.media.phone} {
    margin-right: 4px;
  }
`
export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;

  & + & {
    margin-left: 12px;
  }
  ${({ theme }) => theme.media.phone} {
    align-items: center;
    & + & {
      margin-left: 0;
    }
  }

  &:hover {
    background-color: ${({ theme }) => getAlphaRgb(theme.colors.grey2, 0.4)};

    ${PhotoIcon}, ${AMQIcon}, ${ArticleIcon} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export const PhotoOption = styled(Option)`
  ${({ theme }) => theme.media.phone} {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    & + & {
      margin-left: 0;
      margin-top: 12px;
    }
    ${PhotoIcon} {
      margin-bottom: 4px;
      margin-right: 0;
    }
  }
`

export const StartPostWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
