import styled, { css } from 'styled-components'
import { getAlphaRgb } from 'components/layouts'
import { Button } from 'components/elements'
import { GoComment } from 'react-icons/go'
import { BiLike } from 'react-icons/bi'
import YouTube from 'react-youtube'

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  & + & {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid ${({ theme }) => getAlphaRgb(theme.colors.grey, 0.5)};
  }

  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  ${({ theme }) => theme.media.tabletMini} {
    flex-direction: column;
  }
`

export const PostBanner = styled.div`
  height: 154px;
  min-width: 272px;
  max-width: 272px;
  border-radius: 0px;
  background-color: ${({ theme }) => theme.colors.grey};
  margin-right: 16px;
  position: relative;
  cursor: pointer;

  ${({ theme }) => theme.media.phone} {
    max-width: unset;
    width: 100%;
    height: 193px;
    margin-bottom: 24px;
  }
`
export const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
`

export const PostInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PostHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`

export const StyledLink = styled.a`
  text-decoration: unset;
  color: ${({ theme }) => theme.colors.black};
`

export const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`
export const AuthorImg = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryL};
  border-radius: 50%;
  height: 31px;
  width: 31px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
`
export const AuthorDesc = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 5px;
`
export const SocialDesc = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;
`
export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  ${({ isLiked }) =>
    isLiked &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}
`

export const CommentIcon = styled(GoComment)`
  font-size: 20px;
  margin-top: 5px;
`
export const LikeIcon = styled(BiLike)`
  font-size: 20px;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        color: ${({ theme }) => theme.colors.primary};
      }
    `}
`

export const PostPublishDate = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey};
  text-align: left;
`

export const PostTitle = styled.h3`
  max-width: 800px;
  font-weight: 400;
  line-height: 30px;
  font-size: 18px;
  cursor: pointer;
  margin: 5px 0;
  word-break: break-word;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const CommentContainer = styled.a`
  display: flex;
  align-items: center;
  margin-right: 15px;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.phone} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 10px;
    margin-left: -10px;
  }
`

export const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey2};
  }
`

export const DeleteContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 416px;
  margin: 0 auto;
  height: 100%;
  min-height: 300px;
  max-height: 100vh;
  ${({ theme }) => theme.media.phone} {
    padding: 0 15px;
  }
`
export const DeleteTitle = styled.div`
  ${({ theme }) => theme.font.h3}
  margin-bottom: 32px;
  text-align: center;
  max-width: 478px;

  ${({ theme }) => theme.media.phone} {
    br {
      display: none;
    }
  }
`
export const DeleteButton = styled(Button)`
  & + & {
    margin-top: 8px;
  }
`

export const StyledYouTube = styled(YouTube)`
  margin-top: 10px;

  iframe {
    width: 100%;
    max-width: 500px;
    max-height: 280px;
  }

  ${({ theme }) => theme.media.phone} {
    iframe {
      width: 100%;
      max-width: unset;
      max-height: 200px;
    }
  }
`

export const ExternalPreview = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 4px;
  overflow: hidden;
`

export const PreviewDescPanel = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 16px;
  display: flex;
  flex-direction: column;

  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
`
export const PreviewTitle = styled.div`
  ${({ theme }) => theme.font.bold}
  ${({ theme }) => theme.utils.ellipsis}
`
export const PreviewDesc = styled.div`
  ${({ theme }) => theme.utils.ellipsis}
  font-weight: 400;
`

export const ShowComments = styled.div`
  cursor: pointer;
  width: fit-content;
  /* margin-top: 10px; */
  /* color: ${({ theme }) => theme.colors.grey}; */
  /* background: white; */
  /* padding: 8px 12px; */
  /* border-radius: 4px; */
  /* border: 1px solid ${({ theme }) => theme.colors.grey2}; */
`

export const CommentsWrapper = styled.div`
  display: flex;
  align-items: center;
`
