import * as R from 'ramda'
import api from 'shared/api'

export const doLoadTags = async () => {
  const { data: list } = await api.get('/public/questions/top-20-topics')

  return list
}

export const doLoadFeed = async ({
  type,
  page,
  tagSlugs = [],
  accessToken,
}) => {
  const tagFilters = R.isEmpty(tagSlugs)
    ? ''
    : R.map(slug => `tagSlugs=${slug}`, tagSlugs).join('&') || []

  const {
    data: list,
    headers: { 'x-total-results': total },
  } = await api.get(
    `/public/questions/feed?type=${type}&page=${page}&limit=20&${tagFilters}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )

  return { list, total }
}

export const loadTopWriters = async () => {
  const {
    data: list,
    headers: { 'x-total-results': total },
  } = await api.get(`/public/top-writers?page=1&limit=10`)
  return {
    list,
    total,
  }
}

export const doPostAMQ = async ({ values, accessToken }) => {
  const {
    tags: _tags = [],
    links = [],
    documents = [],
    group: _group,
    ...rest
  } = values

  const tags = _tags && _tags.map(t => t?.id)
  const references = R.isNil(links) ? null : [...links, ...documents]
  const group = _group === 'followers' ? null : _group

  const body = {
    ...rest,
    tags,
    references,
    group,
  }

  const { data } = await api.post('/questions', body, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  return { data }
}

export const loadTags = async ({ accessToken, name }) => {
  if (!name) {
    return { list: [] }
  }
  const { data: list } = await api.get(`/tags?name=${name}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return { list }
}

export const uploadFiles = async ({ list, accessToken }) => {
  const uploadRequests = list.map(async doc => {
    const { data: postData } = await api.post(
      '/upload/file',
      {
        extension: 'pdf',
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    await api.put(postData?.url, doc)
    return {
      type: 'File',
      title: doc?.path,
      value: postData?.fileName,
      isNew: true,
    }
  })

  return await Promise.all(uploadRequests)
}

export const getAutoTag = async ({ statement, tags }) => {
  if (!R.isEmpty(tags)) {
    return tags
  }

  const { data: list } = await api.post('/tags/suggestion', {
    value: statement,
  })
  return R.take(5, list)
}
