import * as R from 'ramda'
import api from 'shared/api'

import { getLink } from './utils'

export const loadTags = async ({ accessToken, name }) => {
  if (!name) {
    return { list: [] }
  }
  const { data: list } = await api.get(`/tags?name=${name}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return { list }
}

const uploadImage = async ({ imageSrc, accessToken, isNew }) => {
  if (R.is(String, imageSrc)) {
    return isNew ? '94afe19e-0719-4b11-0126-28d0a625bb00' : null
  }

  try {
    const { data: postData } = await api.post('/upload/image', null, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })

    const formData = new FormData()

    formData.append('file', imageSrc)

    await api.post(postData?.postUrl, formData)

    return postData?.id
  } catch (error) {
    throw new Error('We could not upload your image. Please try again later.')
  }
}

export const grabInfo = async ({ accessToken, link }) => {
  const { data: preview } = await api.post(
    '/grab',
    {
      url: link,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
  return preview
}

const getPreviewProps = (preview, link) => ({
  previewImage: preview?.image,
  previewTitle: preview?.title,
  previewDescription: preview?.description,
  previewLink: link,
})

const getNewLink = async ({ accessToken, link }) => {
  try {
    const preview = await grabInfo({ accessToken, link })
    return preview
  } catch (e) {
    return null
  }
}

export const doPost = async ({
  groupId,
  accessToken,
  values,
  displayPhoto,
  lastGrab,
  usePreview,
}) => {
  const { text, preview, link } = values
  const imageSrc = R.head(values?.image || [])
  const image = displayPhoto
    ? imageSrc && (await uploadImage({ imageSrc, accessToken }))
    : null

  const firstLink = getLink(text)
  const hasNewLink = firstLink !== lastGrab

  if (hasNewLink && usePreview) {
    const newPreview = await getNewLink({ accessToken, link: firstLink })

    const previewProps = newPreview
      ? getPreviewProps(newPreview, firstLink)
      : {}

    const { data: postData } = await api.post(
      '/posts',
      {
        text,
        image,
        group: groupId,
        ...previewProps,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    return postData
  }

  const previewProps = usePreview ? getPreviewProps(preview, link) : {}

  const { data: postData } = await api.post(
    '/posts',
    {
      text,
      image,
      group: groupId,
      ...previewProps,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
  return postData
}
