import * as R from 'ramda'
import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useSession } from 'context/Session'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { getCloudFlareImgVariant } from 'components/helpers/withSizes'
import {
  Modal,
  // KarmaMedal
} from 'components/elements'
import { HostForm } from 'routes/AskMeQuestions/List/components/Header.styles.js'
import AuthSteps from 'components/collections/AuthSteps'
import { withSizes } from 'components/helpers'
import PostForm from './PostForm'

import * as S from './PostInput.styles'

const defaultProps = {}
const propTypes = {}

const WriteButton = ({ isStaff, user, setOpen }) => {
  const router = useRouter()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const openSetupModal = () => {
    setOpen('auth', {
      defaultStep: !user?.isProfileComplete ? 'setup' : 'welcome',
      defaultValues: user?.profile,
      stepCustomConfig: {
        setup: {
          desc: 'Complete your profile setup before writing an article',
        },
      },
    })
  }

  const handleClick = event => {
    if (!user?.isProfileComplete || !user?.isEmailConfirmed) {
      openSetupModal()
    } else if (!user?.viewedValueProposition) {
      router.push('/publish-on-trialsite')
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (isStaff) {
    return (
      <>
        <S.Option onClick={handleClick} variant="text">
          <S.ArticleIcon />
          <span>Write article</span>
        </S.Option>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => {
              router.push('/write')
              handleClose()
            }}
          >
            Write
          </MenuItem>
          <MenuItem
            onClick={() => {
              router.push('/write?staff=true')
              handleClose()
            }}
          >
            Write as TSN
          </MenuItem>
        </Menu>
      </>
    )
  }

  return (
    <S.Option
      onClick={() => {
        if (!user?.isProfileComplete || !user?.isEmailConfirmed) {
          openSetupModal()
        } else if (!user?.viewedValueProposition) {
          router.push('/publish-on-trialsite')
        } else {
          router.push('/write')
        }
      }}
    >
      <S.ArticleIcon />
      <span>Write article</span>
    </S.Option>
  )
}

const AskButton = ({ isStaff, setOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  if (isStaff) {
    const handleClose = () => {
      setAnchorEl(null)
    }
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    return (
      <>
        <S.Option onClick={handleClick} variant="text">
          <S.AMQIcon />
          <span>Ask a Question</span>
        </S.Option>
        {/* <S.Option onClick={() => setOpen('host')}>
            <S.AMQIcon />
            <span>Ask a Question</span>
          </S.Option> */}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => {
              setOpen('host', {
                asTSN: false,
              })
              handleClose()
            }}
          >
            Ask
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpen('host', {
                asTSN: true,
              })
              handleClose()
            }}
          >
            Ask as TSN
          </MenuItem>
        </Menu>
      </>
    )
  }

  return (
    <S.Option onClick={() => setOpen('host')}>
      <S.AMQIcon />
      <span>Ask a Question</span>
    </S.Option>
  )
}

const modalMap = {
  host: HostForm,
  post: PostForm,
  auth: AuthSteps,
}

export const PostInput = ({ className, isMobile, groupId, onPost }) => {
  const { user, accessToken, clearSession, verifyPermission } = useSession()
  const { profile } = user || {}
  const [openModal, _setOpen] = useState(null)
  const [modalConfig, setModalConfig] = useState({})
  const [isModalOpen, setIsOpen] = useState(false)

  const isStaff = verifyPermission(['Admin', 'Staff', 'SuperAdmin'])

  const ModalComponent = modalMap[openModal]

  const setOpen = (modalName, config = {}) => {
    _setOpen(modalName)
    setIsOpen(true)
    setModalConfig(config)
  }

  const config = {
    user,
    accessToken,
    clearSession,
    isStaff,
    setOpen,
    isMobile,
  }

  return (
    <S.Container className={className}>
      <S.ProfileWrapper>
        <Link href="/dashboard" passHref>
          <S.AvatarContainer>
            <S.Avatar>
              {profile?.profileImage ? (
                <S.ImgProfile
                  data-test="icon"
                  src={getCloudFlareImgVariant(profile?.profileImage, {
                    default: 'profileDetail',
                  })}
                  alt="article"
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  quality={90}
                  priority
                  loading="eager"
                />
              ) : (
                profile && R.head(profile?.username || 'U')
              )}
            </S.Avatar>
          </S.AvatarContainer>
        </Link>
        {isMobile && (
          <S.ProfileInfo>
            <S.Title>{profile && profile?.username}</S.Title>
            {/* <S.InfoContainer>
              <S.Info style={{ cursor: 'pointer' }}>
                <S.InfoTitle>Merit</S.InfoTitle>
                <S.KarmaValue>
                  <KarmaMedal value={profile?.karma} />
                  &nbsp;
                  {profile?.karma || 0}
                </S.KarmaValue>
              </S.Info>
            </S.InfoContainer> */}
          </S.ProfileInfo>
        )}
      </S.ProfileWrapper>
      <S.ActionsContainer>
        <S.StartPostWrapper>
          <S.StartPostButton onClick={() => setOpen('post')}>
            Start a post
          </S.StartPostButton>
          {isMobile && (
            <S.PhotoOption
              onClick={() => setOpen('post', { displayPhoto: true })}
            >
              <S.PhotoIcon />
              <span>Photo</span>
            </S.PhotoOption>
          )}
        </S.StartPostWrapper>
        <S.PostOptions>
          {!isMobile && (
            <S.PhotoOption
              onClick={() => setOpen('post', { displayPhoto: true })}
            >
              <S.PhotoIcon />
              <span>Photo</span>
            </S.PhotoOption>
          )}
          <AskButton {...config} />
          <WriteButton {...config} />
        </S.PostOptions>
      </S.ActionsContainer>

      <Modal
        isOpen={isModalOpen}
        close={() => setIsOpen(false)}
        closeOnOutside={false}
      >
        <ModalComponent
          {...modalConfig}
          groupId={groupId}
          onPost={onPost}
          setOpen={setIsOpen}
        />
      </Modal>
    </S.Container>
  )
}

PostInput.defaultProps = defaultProps
PostInput.propTypes = propTypes

export default withSizes(PostInput)
