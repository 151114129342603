import styled, { css } from 'styled-components'
import { getAlphaRgb } from 'components/layouts'
import { MdOutlinePhoto, MdClose } from 'react-icons/md'

export const FormTitle = styled.div`
  ${({ theme }) => theme.font.h3}
  margin-bottom: 12px;
`
export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const AvatarContainer = styled.div`
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  position: relative;
  margin-right: 16px;
`
export const Avatar = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;

  ${({ theme }) => theme.media.phone} {
    font-size: 40px;
  }
`
export const ImgProfile = styled.div`
  height: 100%;
  width: 100%;
  min-height: 50px;
  ${({ src }) =>
    src &&
    css`
      background-image: ${`url(${src})`};
      background-size: cover;
      background-position: center;
    `};
`
export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 24px;
  }
`
export const ProfileName = styled.div`
  ${({ theme }) => theme.font.h3}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 72px 64px;
  ${({ theme }) => theme.media.phone} {
    padding: 10px 16px 64px;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 416px;
  display: flex;
  flex-direction: column;
  div {
    ${({ theme }) => theme.font.label}
  }
`
export const ImgPreview = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 234px;
  width: 100%;
  max-width: 416px;
  background: ${({ theme }) => theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  margin-bottom: 8px;
  ${({ src }) =>
    src &&
    css`
      background-image: ${`url(${src})`};
      background-size: cover;
      background-position: center;
      &:hover {
        &::after {
          content: 'Edit';
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: ${({ theme }) => getAlphaRgb(theme.colors.grey, 0.5)};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    `};
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}

  ${({ theme }) => theme.media.phone} {
    width: 100%;
    height: 193px;
    max-width: unset;
  }
`

const sectionsBorder = css`
  border-top: solid 1px ${({ theme }) => theme.colors.grey};
`
export const FormSection = styled.div`
  margin-top: 32px;
  padding-top: 24px;
  ${sectionsBorder}
`

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
`

export const PostButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  ${({ theme }) => theme.media.phone} {
    width: 100%;
  }
`

export const PhotoIcon = styled(MdOutlinePhoto)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 12px;
`
export const CloseIcon = styled(MdClose)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 12px;
`

export const ExternalPreview = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 416px;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 18px;
`

export const PreviewImg = styled.div`
  flex: 1;
  overflow: hidden;
`
export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const PreviewDescPanel = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 16px;
  display: flex;
  flex-direction: column;
  /* min-height: 80px; */

  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
`
export const PreviewTitle = styled.div`
  ${({ theme }) => theme.font.bold}
  ${({ theme }) => theme.utils.ellipsis}
`
export const PreviewDesc = styled.div`
  ${({ theme }) => theme.utils.ellipsis}
  font-weight: 400;
`

export const PreviewWrapper = styled.div`
  position: relative;
  width: fit-content;
  max-width: 100%;
`
export const RemovePreview = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.lightGrey};
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  &:hover {
    background: ${({ theme }) => theme.colors.grey2};
  }

  ${CloseIcon} {
    margin: 0;
  }

  ${({ theme }) => theme.media.phone} {
    top: -12px;
    right: -12px;
  }
`
