import styled, { css, createGlobalStyle } from 'styled-components'
import { MdArrowBack } from 'react-icons/md'
import { GoComment } from 'react-icons/go'
import YouTube from 'react-youtube'
import { Button } from 'components/elements'

export const DisableBodyOverflow = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

export const Container = styled.div`
  position: absolute;
  top: 74px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 100;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const Header = styled.div`
  padding: 13px;
  display: flex;
  align-items: center;
  /* position: sticky;
  top: 0px; */
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;
`
export const Title = styled.div`
  ${({ theme }) => theme.font.h3}
`
export const Close = styled(MdArrowBack)`
  cursor: pointer;
  right: 10px;
  padding: 4px;
  font-size: 36px;
  /* position: absolute;
  top: 20px;
  right: 20px; */
`
export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 704px;
  margin: 0 auto;
  padding-bottom: 32px;
  ${({ theme }) => theme.media.tablet} {
    padding: 0 32px;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 0 16px;
  }
`
export const PostImg = styled.img`
  position: relative;
  overflow: hidden;
  max-height: 50vh;
  object-fit: contain;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  margin-bottom: 8px;
  ${({ src }) =>
    src &&
    css`
      content: url(${src});
    `};

  ${({ theme }) => theme.media.phone} {
    max-width: unset;
    max-height: unset;
  }
`

export const Description = styled.div`
  max-width: 700px;
  font-weight: 500;
  line-height: 28px;
  font-size: 18px;
  margin-bottom: 18px;
  white-space: break-spaces;

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`
export const Postdate = styled.div`
  ${({ theme }) => theme.font.body};
`

export const CommentsSection = styled.div`
  background: ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  height: auto;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ShareWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const OptionsContent = styled.div`
  display: flex;
  align-items: center;
`
export const CommentsCount = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`
export const CommentIcon = styled(GoComment)`
  font-size: 22px;
  margin-right: 6px;
  margin-top: 5px;
`

export const DeleteContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 416px;
  margin: 0 auto;
  height: 100%;
  min-height: 300px;
  max-height: 100vh;
  ${({ theme }) => theme.media.phone} {
    padding: 0 15px;
  }
`
export const DeleteTitle = styled.div`
  ${({ theme }) => theme.font.h3}
  margin-bottom: 32px;
  text-align: center;
  max-width: 478px;

  ${({ theme }) => theme.media.phone} {
    br {
      display: none;
    }
  }
`
export const DeleteButton = styled(Button)`
  & + & {
    margin-top: 8px;
  }
`

export const ExternalPreview = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 18px;
`

export const PreviewImg = styled.div`
  flex: 1;
  overflow: hidden;
`
export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const PreviewDescPanel = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 16px;
  display: flex;
  flex-direction: column;

  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
`
export const PreviewTitle = styled.div`
  ${({ theme }) => theme.font.bold}
  ${({ theme }) => theme.utils.ellipsis}
`
export const PreviewDesc = styled.div`
  ${({ theme }) => theme.utils.ellipsis}
  font-weight: 400;
`

export const StyledYouTube = styled(YouTube)`
  margin-bottom: 30px;

  iframe {
    width: 100%;
  }

  ${({ theme }) => theme.media.phone} {
    iframe {
      max-height: 200px;
    }
  }
`
