import * as R from 'ramda'
import React, { useState, useEffect } from 'react'
import { Form } from 'components/collections'
import { Button } from 'components/elements'
import { useSnackbar } from 'notistack'
import { useSession } from 'context/Session'
import { getCloudFlareImgVariant } from 'components/helpers/withSizes'
import { useDebounce } from 'components/helpers/utils'

import { doPost, grabInfo } from '../modules/datasource'
import { getLink } from '../modules/utils'

import * as S from './PostForm.styles'

const defaultProps = {}
const propTypes = {}
export const PostForm = ({
  groupId,
  displayPhoto: defaultPhoto,
  setOpen,
  onPost,
}) => {
  const { user, accessToken, loadGroups } = useSession()
  const { enqueueSnackbar } = useSnackbar()
  const [isFetching, setIsFetching] = useState(false)
  const [postContent, setPostContent] = useState('')
  const [isUploading] = useState(false)
  const [displayPhoto, setDisplayPhoto] = useState(defaultPhoto)
  const [groupOptions, setGroupsOptions] = useState([])
  const [preview, setPreview] = useState(null)
  const [usePreview, setUsePreview] = useState(true)
  const [lastGrab, setLastGrab] = useState(null)
  const [isGrabbing, setIsGrabbing] = useState(false)

  const { profile } = user || {}

  useEffect(async () => {
    const response = await loadGroups()
    setGroupsOptions(
      response.map(group => ({
        value: group.id,
        label: group.name,
      }))
    )
  }, [])

  const link = useDebounce(getLink(postContent), 2000)

  const onSubmit = async values => {
    setIsFetching(true)
    try {
      const postData = await doPost({
        groupId,
        accessToken,
        displayPhoto,
        usePreview,
        lastGrab,
        values: {
          ...values,
          text: postContent,
          preview: usePreview ? preview : null,
          link,
        },
      })
      setIsFetching(false)
      setOpen(false)
      onPost && onPost({ postData })
    } catch (e) {
      setIsFetching(false)
      const message =
        e?.response?.data?.message ||
        e?.response?.data ||
        e?.message ||
        'Something went wrong'

      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  useEffect(async () => {
    if (link !== lastGrab) {
      setLastGrab(link)
      if (link && usePreview) {
        setIsGrabbing(true)
        try {
          const data = await grabInfo({ accessToken, link })
          setPreview(data)
          setIsGrabbing(false)
        } catch (e) {
          setIsGrabbing(false)
          setPreview(null)
        }
      } else {
        setPreview(null)
      }
    }
  }, [link, usePreview, lastGrab])

  return (
    <Form
      onSubmit={onSubmit}
      style={{ width: '100%' }}
      initialValues={{
        group: groupId ? Number(groupId) : 'followers',
      }}
    >
      <S.Container>
        <S.FormTitle>Create a post</S.FormTitle>
        <S.FormHeader>
          <S.ProfileWrapper>
            <S.AvatarContainer>
              <S.Avatar>
                {profile?.profileImage ? (
                  <S.ImgProfile
                    data-test="icon"
                    src={getCloudFlareImgVariant(profile?.profileImage, {
                      default: 'profileDetail',
                    })}
                    alt="article"
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    quality={90}
                    priority
                    loading="eager"
                  />
                ) : (
                  profile && R.head(profile?.username || 'U')
                )}
              </S.Avatar>
            </S.AvatarContainer>
            <S.ProfileName>{profile?.username || ''}</S.ProfileName>
          </S.ProfileWrapper>

          <Form.Select
            label="Post for"
            style={{ width: 220 }}
            options={[
              { value: 'followers', label: 'My feed' },
              ...groupOptions,
            ]}
            name="group"
          />
        </S.FormHeader>
        <Form.Input
          label="Your post"
          multiline
          minRows={4}
          inputProps={{
            maxLength: 3000,
          }}
          onFocus={e =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
          value={postContent}
          onChange={e => setPostContent(e.target.value)}
          helperText={`${postContent.length}/3000`}
          autoFocus
        />
        {usePreview && preview && (
          <S.FormSection>
            <S.PreviewWrapper>
              {!isFetching && (
                <S.RemovePreview onClick={() => setUsePreview(false)}>
                  <S.CloseIcon />
                </S.RemovePreview>
              )}
              <S.ExternalPreview hasimg={!displayPhoto && preview?.image}>
                {!displayPhoto && preview?.image && (
                  <S.PreviewImg>
                    <S.StyledImg src={preview?.image} />
                  </S.PreviewImg>
                )}
                <S.PreviewDescPanel>
                  <S.PreviewTitle>{preview?.title}</S.PreviewTitle>

                  {preview?.description && (
                    <S.PreviewDesc>{preview?.description}</S.PreviewDesc>
                  )}
                </S.PreviewDescPanel>
              </S.ExternalPreview>
            </S.PreviewWrapper>
          </S.FormSection>
        )}
        {displayPhoto && (
          <S.FormSection>
            <S.ImageContainer>
              <Form.Upload
                disabled={isFetching || isUploading}
                name="image"
                renderInput={(f = []) => {
                  const previewUrl = R.head(f)

                  return (
                    <S.ImgPreview
                      src={
                        previewUrl?.preview || (!R.isEmpty(f) && f) || undefined
                      }
                      disabled={isFetching || isUploading}
                    >
                      {previewUrl ? '' : 'Add image'}
                    </S.ImgPreview>
                  )
                }}
              />
            </S.ImageContainer>
          </S.FormSection>
        )}
        <S.FormFooter>
          <S.PostButtonContainer>
            <Button
              disabled={isFetching || isUploading || isGrabbing}
              loading={isFetching || isGrabbing}
              style={{ width: '100%', maxWidth: '166px' }}
              type="submit"
            >
              Post
            </Button>
            <Button
              disabled={isFetching || isUploading}
              style={{ width: '100%', maxWidth: '166px' }}
              type="button"
              variant="text"
              onClick={() => {
                setDisplayPhoto(R.not)
              }}
            >
              {displayPhoto ? <S.CloseIcon /> : <S.PhotoIcon />} Photo
            </Button>
          </S.PostButtonContainer>
        </S.FormFooter>
      </S.Container>
    </Form>
  )
}

PostForm.defaultProps = defaultProps
PostForm.propTypes = propTypes

export default PostForm
