// import * as R from 'ramda'
import api from 'shared/api'

export const doLoadPost = async () => {
  // const { data } = await api.get('/public/posts/1')
  return { data: {} }
}

export const deletePost = async ({ accessToken, postId }) => {
  await api.delete(`/posts/${postId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const publishComment = async ({
  text,
  createAsStaff,
  reference,
  parentId,
  accessToken,
}) => {
  return api.post(
    `/comments`,
    {
      text,
      type: 'Post',
      reference,
      parentId,
      createAsStaff,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const deleteComment = async ({ accessToken, id }) => {
  await api.delete(`/comments/${id}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const editComment = async ({ text, id, accessToken }) => {
  return api.put(
    `/comments/${id}`,
    {
      text,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const loadComments = async ({
  reference,
  accessToken,
  page = 1,
  limit = 10,
  parentId,
}) => {
  const {
    data: list,
    headers: { 'x-total-results': total },
  } = await api.get('/comments', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      type: 'Post',
      reference,
      startFromId: parentId,
      page,
      limit,
    },
  })

  return { list, total }
}

export const like = async ({ accessToken, postId }) => {
  await api.post(`/posts/like/${postId}`, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const unlike = async ({ accessToken, postId }) => {
  await api.delete(`/posts/like/${postId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const loadIsLiked = async ({ articleId, accessToken }) => {
  const { data: isLiked } = await api.get(`/articles/liked/${articleId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  return isLiked
}
