import * as R from 'ramda'
import * as yup from 'yup'
import React, { useState, useEffect } from 'react'
import slugfy from 'slug'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import { useQuery } from 'react-query'
import { useDropzone } from 'react-dropzone'
import Autocomplete from '@mui/material/Autocomplete'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useSnackbar } from 'notistack'
import { useFormikContext } from 'formik'
import { Input, Button, Loading, Tag, Modal } from 'components/elements'
import { Form } from 'components/collections'
import { useDebounce } from 'components/helpers/utils'
import { useSession } from 'context/Session'
import { BiLink } from 'react-icons/bi'
import { MdPictureAsPdf } from 'react-icons/md'
import AuthSteps from 'components/collections/AuthSteps'

import {
  doPostAMQ,
  loadTags,
  uploadFiles,
  getAutoTag,
} from '../modules/datasource'

const getFinalUrl = async str => {
  const checkStr = R.includes('https://', str) ? str : 'https://' + str
  const schema = yup.string().url()
  const valid = await schema.isValid(checkStr)
  return valid ? checkStr : null
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 64px 160px 32px;
  ${({ theme }) => theme.media.desktop} {
    padding: 64px 60px 32px;
  }
  ${({ theme }) => theme.media.tablet} {
    padding: 56px 32px 28px;
    flex-direction: column;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 30px 16px;
    flex-direction: column;
  }
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  ${({ theme }) => theme.media.tabletMini} {
    flex-direction: column;
  }
`

export const Title = styled.h1`
  ${({ theme }) => theme.font.h1}
  margin: 0;
  max-width: 1440px;
`

export const ButtonContainer = styled.div`
  width: 300px;
  ${({ theme }) => theme.media.tablet} {
    margin-top: 30px;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 72px 64px;
  ${({ theme }) => theme.media.phone} {
    padding: 0 16px 64px;
  }
`
const FormHeader = styled.div`
  ${({ theme }) => theme.font.h3}
  margin-bottom: 48px;
`
const FormContent = styled.div`
  display: flex;
  flex-direction: column;
`
const PostButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  ${({ theme }) => theme.media.phone} {
    width: 100%;
  }
`

const sectionsBorder = css`
  border-top: solid 1px ${({ theme }) => theme.colors.grey};
`

const TagsSection = styled.div`
  & + & {
    margin-top: 32px;
    padding-top: 24px;
    ${sectionsBorder}
  }
`
export const TagsTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`
export const TagsContent = styled.div`
  display: flex;
  .MuiAutocomplete-root {
    width: 200px;
  }
  .MuiOutlinedInput-root {
    padding: 7px;
  }

  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 18px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`
export const TagInput = styled(Input)``
export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: -4px 16px;
  ${({ theme }) => theme.media.phone} {
    margin: 16px -4px 0;
  }
`
export const TagItem = styled(Tag)`
  margin: 4px;
`

const Tags = ({ isFetching, accessToken }) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const { values, setFieldValue } = useFormikContext()
  const { tags = [] } = values

  // const tagsList = useTags(accessToken, inputValue)

  const debouncedSearchTerm = useDebounce(inputValue, 500)

  const { data: tagsData = {}, isLoading } = useQuery(
    ['tags', { accessToken, debouncedSearchTerm }],
    () => loadTags({ accessToken, name: debouncedSearchTerm }),
    {
      fetchPolicy: 'no-cache',
    }
  )

  const { list: tagsList = [] } = tagsData

  return (
    <TagsSection>
      <TagsTitle>
        Add up to 5 tags to help reader know what it’s about
      </TagsTitle>
      <TagsContent>
        <Autocomplete
          size="small"
          disabled={isFetching || R.length(tags) >= 5}
          multiple
          open={open}
          loading={isLoading}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          disableClearable
          renderTags={() => null}
          value={tags}
          isOptionEqualToValue={(option, value) => {
            return option?.id === value?.id
          }}
          onChange={(_, value) => {
            setFieldValue('tags', value)
          }}
          options={tagsList}
          noOptionsText="Type to search"
          renderInput={params => (
            <TagInput
              {...params}
              label="Tag"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <Loading size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue)
          }}
          getOptionLabel={o => o?.name}
        />
        {!R.isEmpty(tags) && (
          <TagList>
            {tags.map(item => (
              <TagItem
                disabled={isFetching}
                key={`tag-${item?.name}`}
                label={item?.name}
                onRemove={() => setFieldValue('tags', R.without([item], tags))}
              />
            ))}
          </TagList>
        )}
      </TagsContent>
    </TagsSection>
  )
}
export const ReferencesContent = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(100px, 344px) 1fr;
  grid-gap: 26px 16px;
  ${({ theme }) => theme.media.phone} {
    grid-template-columns: 1fr;
  }
`
export const ReferencesTitleInput = styled(Input)`
  &.MuiTextField-root {
    width: 200px;
    height: 48px;
  }
  .MuiOutlinedInput-input {
    padding: 14.5px 14px;
  }
  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 18px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`
export const ReferenceLinkInput = styled(ReferencesTitleInput)`
  &.MuiTextField-root {
    width: 100%;
  }
`
export const ReferenceAddButton = styled(Button)`
  height: 48px;
  width: 56px;
`
export const RefList = styled.div`
  grid-column: 1 / 4;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: -4px;
  ${({ theme }) => theme.media.phone} {
    grid-column: 1;
  }
`
export const TagLabel = styled.div`
  display: flex;
  align-items: center;
`
export const LinkIcon = styled(BiLink)`
  font-size: 18px;
  margin-right: 10px;
`
export const PdfIcon = styled(MdPictureAsPdf)`
  font-size: 18px;
  margin-right: 10px;
`

const Links = ({ isFetching }) => {
  const [error, setError] = useState(null)
  const [key, setKey] = useState(0)
  const [title, setTitle] = useState('')
  const [linkRef, setLinkRef] = useState('')
  const { values, setFieldValue } = useFormikContext()
  const { links = [] } = values

  const addReferenceLink = async () => {
    const finalUrl = await getFinalUrl(linkRef)
    if (finalUrl) {
      const currentLinks = R.pluck('value', links)
      if (R.includes(finalUrl, currentLinks)) {
        setError('You already added this link')
        return
      }
      setFieldValue('links', [
        ...links,
        {
          type: 'Link',
          title: title,
          value: finalUrl,
        },
      ])
      setTitle('')
      setLinkRef('')
      setKey(R.add(1))
    } else {
      setError('Must be a valid url')
    }
  }

  return (
    <TagsSection>
      <TagsTitle>Create a reference link</TagsTitle>
      <ReferencesContent>
        <ReferencesTitleInput
          key={`refTItle-${key}`}
          onChange={event => {
            setTitle(event.target.value)
          }}
          value={title}
          label="Reference title"
          size="small"
        />
        <ReferenceLinkInput
          key={`refLink-${key}`}
          onChange={event => {
            setError(null)
            setLinkRef(event.target.value)
          }}
          value={linkRef}
          fullWidth
          error={error}
          helperText={error}
          label="Paste or type a link"
          size="small"
        />
        <ReferenceAddButton
          onClick={addReferenceLink}
          type="button"
          variant="text"
          disabled={isFetching || R.isEmpty(linkRef)}
        >
          Add
        </ReferenceAddButton>

        {!R.isEmpty(links) && (
          <RefList>
            {links.map(item => (
              <TagItem
                disabled={isFetching}
                key={`reftag-${item?.value}`}
                label={
                  <TagLabel>
                    <LinkIcon />
                    {item?.title || item?.value}
                  </TagLabel>
                }
                onRemove={() =>
                  setFieldValue('links', R.without([item], links))
                }
              />
            ))}
          </RefList>
        )}
      </ReferencesContent>
    </TagsSection>
  )
}
export const DocumentsSection = styled.div`
  margin-top: 32px;
  padding-top: 8px;
  ${sectionsBorder}
`
export const DocumentsContent = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`
export const DocumentsAddButton = styled(Button)`
  height: 56px;
  width: 181px;
  padding: 0;
`
export const DocumentsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: -4px;
  margin-left: 32px;
  ${({ theme }) => theme.media.phone} {
    margin-left: -4px;
    margin-top: 16px;
  }
`

const Documents = ({
  isFetching,
  accessToken,
  isUploading,
  setIsUploading,
  formData,
}) => {
  const [files, setFiles] = useState(formData?.documents || [])
  const { setFieldValue } = useFormikContext()
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
    disabled: isFetching || isUploading,
    accept: '.pdf',
    onDrop: async acceptedFiles => {
      setIsUploading(true)
      const uploadedFiles = await uploadFiles({
        list: acceptedFiles,
        accessToken,
      })
      setFiles(x => [...x, ...uploadedFiles])
      setIsUploading(false)
    },
  })

  useEffect(() => {
    setFieldValue('documents', files)
  }, [files])

  return (
    <DocumentsSection>
      <DocumentsContent>
        <input {...getInputProps()} style={{ display: 'none' }} />
        <DocumentsAddButton
          type="button"
          variant="text"
          disabled={isFetching || isUploading}
          loading={isUploading}
          {...getRootProps({ className: 'dropzone' })}
        >
          Attach a PDF document
        </DocumentsAddButton>
        {!R.isEmpty(files) && (
          <DocumentsList>
            {files.map((file, index) => (
              <TagItem
                disabled={isFetching}
                key={file.title}
                label={
                  <TagLabel>
                    <PdfIcon />
                    {file.title}
                  </TagLabel>
                }
                onRemove={() => setFiles(R.remove(index, 1))}
              />
            ))}
          </DocumentsList>
        )}
      </DocumentsContent>
    </DocumentsSection>
  )
}

const QuestionForm = ({ setStep, formData, setFormData, asTSN }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isFetching, setIsFetching] = useState(false)
  const [statement, setStatement] = useState(formData?.statement || '')
  const [projectDetails, setProjectDetails] = useState(
    formData?.projectDetails || ''
  )

  const postAMQ = async values => {
    if (!statement || R.length(statement) < 25) {
      enqueueSnackbar('Question title must be between 25 and 180', {
        variant: 'error',
      })
      return
    }
    if (!projectDetails) {
      enqueueSnackbar('You need to describe your question', {
        variant: 'error',
      })
      return
    }
    setIsFetching(true)
    try {
      const autoTag = await getAutoTag({
        statement,
        tags: values?.tags,
      })

      setFormData(v => ({
        ...v,
        statement,
        projectDetails,
        tags: autoTag,
      }))
      setStep('description')
      setIsFetching(false)
    } catch (error) {
      setIsFetching(false)
      enqueueSnackbar(error.response?.data?.message, { variant: 'error' })
    }
  }

  return (
    <>
      <FormHeader>{`Ask your question${asTSN ? ' as TSN' : ''}`}</FormHeader>
      <Form
        onSubmit={postAMQ}
        style={{ width: '100%' }}
        initialValues={{
          tags: [],
          ...formData,
        }}
      >
        <Form.Input
          label="Your question"
          multiline
          minRows={2}
          inputProps={{
            maxLength: 180,
          }}
          onFocus={e =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
          value={statement}
          onChange={e => setStatement(e.target.value)}
          helperText={`${statement.length}/180`}
          autoFocus
        />
        <Form.Input
          label="Describe your question"
          multiline
          minRows={3}
          inputProps={{
            maxLength: 3000,
          }}
          onFocus={e =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
          style={{ marginTop: 20 }}
          value={projectDetails}
          onChange={e => setProjectDetails(e.target.value)}
          helperText={`${projectDetails.length}/3000`}
        />
        <FormContent>
          <PostButtonContainer>
            <Button
              disabled={isFetching}
              loading={isFetching}
              style={{ width: '100%', maxWidth: '166px' }}
              type="submit"
            >
              Next
            </Button>
          </PostButtonContainer>
        </FormContent>
      </Form>
    </>
  )
}

const DescriptionForm = ({
  setStep,
  formData,
  setFormData,
  asTSN,
  groupId,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const router = useRouter()
  const { user, accessToken, loadGroups } = useSession()
  const [isUploading, setIsUploading] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [groupOptions, setGroupsOptions] = useState([])

  useEffect(async () => {
    const response = await loadGroups()
    setGroupsOptions(
      response.map(group => ({
        value: group.id,
        label: group.name,
      }))
    )
  }, [])

  const config = {
    user,
    accessToken,
    isFetching,
    setIsFetching,
    isUploading,
    setIsUploading,
    formData,
  }

  const postAMQ = async values => {
    if (R.isEmpty(values?.tags || [])) {
      enqueueSnackbar('You must add at least one tag', {
        variant: 'error',
      })
      return
    }
    setIsFetching(true)

    try {
      const statement = values?.statement
      const { data } = await doPostAMQ({
        values: {
          ...values,
          text: values?.projectDetails,
          title: statement,
          createAsStaff: asTSN,
        },
        accessToken,
      })
      const { id } = data
      const slug = slugfy(statement)

      router.push(`/questions/${id}/${slug}`)
    } catch (error) {
      setIsFetching(false)
      enqueueSnackbar(error.response?.data?.message, { variant: 'error' })
    }
  }

  return (
    <>
      <FormHeader>Add tags or references to your questions:</FormHeader>
      <Form
        onSubmit={postAMQ}
        style={{ width: '100%' }}
        initialValues={{
          tags: [],
          group: groupId ? Number(groupId) : 'followers',
          ...formData,
        }}
      >
        {({ values }) => (
          <>
            <TagsSection>
              <TagsTitle>Publish also on:</TagsTitle>
              <DocumentsContent>
                <Form.Select
                  options={[
                    { value: 'followers', label: 'Trial Site News' },
                    ...groupOptions,
                  ]}
                  name="group"
                />
              </DocumentsContent>
            </TagsSection>
            <Tags {...config} />
            <Links {...config} />
            <Documents {...config} />
            <FormContent>
              <PostButtonContainer>
                <Button
                  disabled={isFetching || isUploading}
                  loading={isFetching}
                  style={{ width: '100%', maxWidth: '166px' }}
                  type="submit"
                >
                  Post Question
                </Button>
                <Button
                  disabled={isFetching || isUploading}
                  style={{ width: '100%', maxWidth: '166px' }}
                  type="button"
                  variant="text"
                  onClick={() => {
                    setStep('question')
                    setFormData(values)
                  }}
                >
                  Back
                </Button>
              </PostButtonContainer>
            </FormContent>{' '}
          </>
        )}
      </Form>
    </>
  )
}

const StepMap = {
  question: QuestionForm,
  description: DescriptionForm,
}

export const HostForm = props => {
  const [crrStep, setStep] = useState('question')
  const [formData, setFormData] = useState({})

  const stepConfig = {
    ...props,
    setStep,
    formData,
    setFormData,
  }

  const Step = StepMap[crrStep] || StepMap.question

  return (
    <FormContainer>
      <Step {...stepConfig} />
    </FormContainer>
  )
}

const modalMap = {
  signUp: AuthSteps,
  host: HostForm,
}

export const AskQuestionButton = () => {
  const [isOpen, setOpen] = useState(false)
  const { accessToken, verifyPermission } = useSession()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const ModalComponent = modalMap[accessToken ? 'host' : 'signUp']

  const [modalConfig, setModalConfig] = useState({})

  const isStaff = verifyPermission(['Admin', 'Staff', 'SuperAdmin'])

  if (isStaff) {
    const handleClose = () => {
      setAnchorEl(null)
    }
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    return (
      <>
        <Button
          style={{ width: '100%', marginBottom: 30 }}
          onClick={handleClick}
        >
          Ask a question
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => {
              setModalConfig({
                asTSN: false,
              })
              setOpen(true)
              handleClose()
            }}
          >
            Ask
          </MenuItem>
          <MenuItem
            onClick={() => {
              setModalConfig({
                asTSN: true,
              })
              setOpen(true)
              handleClose()
            }}
          >
            Ask as TSN
          </MenuItem>
        </Menu>
        <Modal
          isOpen={isOpen}
          close={() => setOpen(false)}
          closeOnOutside={false}
        >
          <ModalComponent {...modalConfig} />
        </Modal>
      </>
    )
  }

  return (
    <>
      <Button
        style={{ width: '100%', marginBottom: 30 }}
        onClick={() => setOpen(true)}
      >
        Ask a question
      </Button>
      <Modal
        isOpen={isOpen}
        close={() => setOpen(false)}
        closeOnOutside={false}
      >
        <ModalComponent {...modalConfig} />
      </Modal>
    </>
  )
}
