import * as R from 'ramda'
import React, { useState } from 'react'
import Link from 'next/link'
import NProgress from 'nprogress'
import { format } from 'date-fns'
import { useSnackbar } from 'notistack'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { getCloudFlareImgVariant } from 'components/helpers/withSizes'
import { FeedBadge, Modal } from 'components/elements'
import { useSession } from 'context/Session'
import Comments from 'components/collections/Post/PostModal/components/Comments'

import { like, unlike, deletePost } from '../modules/datasource'

import * as S from './FeedPost.styles'

const defaultProps = {}
const propTypes = {}

const generateEllipsis = (text = '', maxSize) => {
  if (R.length(text) > maxSize) {
    return R.trim(R.take(maxSize, text)) + '...'
  }
  return text
}

const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/

const getLink = link => {
  if (!link) return null

  const match = link.match(regExp)

  if (match && match[2].length == 11) {
    return match[2]
  } else {
    return null
  }
}

export const FeedPost = ({
  customPath,
  groupId,
  groupSlug,
  data,
  onPostOpen,
  setListState,
  setCustomItems,
  commentsStyle,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const postPath = customPath
    ? `${customPath}?post=${data?.id}`
    : groupId
    ? `/groups/${groupId}/${groupSlug}?post=${data?.id}`
    : `/?post=${data?.id}`
  const authorPath = `/p/${data?.profile?.username}`
  const { user: sessionUser, accessToken, verifyPermission } = useSession()
  const [isLiked, setIsLiked] = useState(data?.liked)
  const [likeCount, setLikeCount] = useState(data?.likes)
  // const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpen, setOpen] = useState(null)
  const [isLiking, setIsLinking] = useState(false)
  const [displayComments, setDisplayComments] = useState(false)

  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleLike = async () => {
    if (accessToken && !isLiking) {
      NProgress.start()
      setIsLinking(true)
      if (isLiked) {
        await unlike({ accessToken, postId: data?.id })
        setLikeCount(R.subtract(R.__, 1))
      } else {
        await like({ accessToken, postId: data?.id })
        setLikeCount(R.add(1))
      }
      setIsLiked(R.not)
      setIsLinking(false)
      NProgress.done()
    }
  }

  const imgPreview = data?.image || data?.previewImage

  const isMine = data?.profile?.username === sessionUser?.profile?.username

  const isYoutube = getLink(data?.previewLink)

  const hasPreview = data?.previewLink && data?.previewTitle

  const isStaff = verifyPermission(['Admin', 'Staff', 'SuperAdmin'])

  return (
    <S.Container {...rest} onClick={() => onPostOpen && onPostOpen()}>
      {(isMine || isStaff) && (
        <S.Options
          id="options-button"
          key={'options-button'}
          onClick={handleClick}
        >
          <MdOutlineMoreHoriz key="options-icon" />
        </S.Options>
      )}
      <Menu
        id={`post-menu-${data?.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          key={`menuItem-delete`}
          onClick={() => {
            setOpen('post')
            handleClose()
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <Modal
        isOpen={!!isOpen}
        close={() => setOpen(null)}
        closeOnOutside={false}
      >
        <S.DeleteContent>
          <S.DeleteTitle>
            Are you sure you want to delete
            <br /> your {isOpen}?
          </S.DeleteTitle>
          <S.DeleteButton
            type="button"
            onClick={async () => {
              try {
                await deletePost({ accessToken, postId: data?.id })
                setListState(crrState => {
                  const { list = [], total = 0 } = crrState

                  return {
                    list: R.filter(
                      R.complement(R.propEq('id', data?.id)),
                      list
                    ),
                    total: R.subtract(total, 1),
                  }
                })
                if (setCustomItems) {
                  setCustomItems(list => {
                    return R.filter(
                      R.complement(R.propEq('id', data?.id)),
                      list
                    )
                  })
                }
                setOpen(null)
                enqueueSnackbar('Post deleted', { variant: 'success' })
              } catch (error) {
                enqueueSnackbar('Error deleting post', { variant: 'error' })
              }
            }}
          >
            Yes, delete this {isOpen}
          </S.DeleteButton>
          <S.DeleteButton
            type="button"
            variant="text"
            onClick={() => setOpen(null)}
          >
            Cancel
          </S.DeleteButton>
        </S.DeleteContent>
      </Modal>
      {imgPreview && (
        <Link href={postPath} passHref>
          <a rel="noreferrer" style={{ height: 'fit-content' }}>
            <S.PostBanner>
              {imgPreview && (
                <S.StyledImg
                  src={getCloudFlareImgVariant(imgPreview, {
                    default: 'articleImageSmall',
                    phone: 'mobile',
                  })}
                />
              )}
            </S.PostBanner>
          </a>
        </Link>
      )}
      <S.PostInfo>
        <S.PostHeader>
          <S.AuthorWrapper>
            <Link href={authorPath} passHref>
              <S.StyledLink rel="noreferrer">
                <S.AuthorContainer>
                  <S.AuthorImg>
                    {data?.profile?.profileImage ? (
                      <S.StyledImg
                        src={getCloudFlareImgVariant(
                          data?.profile?.profileImage,
                          {
                            default: 'profileSmall ',
                          }
                        )}
                      />
                    ) : (
                      R.head(data?.profile?.username || 'U')
                    )}
                  </S.AuthorImg>
                  <div>{data?.profile?.username}</div>
                </S.AuthorContainer>
              </S.StyledLink>
            </Link>
            <S.TagsContainer>
              <FeedBadge type="post" />
              {data?.groupId && (
                <Link href={`/groups/${data?.groupId}`}>
                  <a>
                    <FeedBadge type="group" label={data?.groupName} />
                  </a>
                </Link>
              )}
            </S.TagsContainer>
          </S.AuthorWrapper>
          <S.AuthorDesc>
            <S.SocialDesc>
              <S.LikeContainer isLiked={isLiked}>
                <S.LikeIcon
                  onClick={accessToken && toggleLike}
                  isLiked={isLiked}
                />
                &nbsp;{likeCount > 0 ? likeCount : ''}
              </S.LikeContainer>
              <S.CommentsWrapper>
                <Link href={postPath + '#comments'} passHref>
                  <S.CommentContainer rel="noreferrer">
                    <S.CommentIcon />
                    &nbsp;{data?.comments > 0 ? data?.comments : ''}
                  </S.CommentContainer>
                </Link>
                {accessToken && !displayComments && (
                  <S.ShowComments onClick={() => setDisplayComments(x => !x)}>
                    {data?.comments > 0 ? 'Show comments' : 'Comment'}
                  </S.ShowComments>
                )}
              </S.CommentsWrapper>
            </S.SocialDesc>
            <S.PostPublishDate>
              {format(new Date(data?.publishedAt), 'MMMM d, yyyy')}
            </S.PostPublishDate>
          </S.AuthorDesc>
        </S.PostHeader>
        <Link href={postPath} passHref>
          <S.StyledLink rel="noreferrer">
            <S.PostTitle>{generateEllipsis(data?.text, 250)}</S.PostTitle>
          </S.StyledLink>
        </Link>
        {!imgPreview && isYoutube && (
          <S.StyledYouTube videoId={isYoutube} id={isYoutube} />
        )}
        {hasPreview && !isYoutube && (
          <S.ExternalPreview>
            <S.PreviewDescPanel>
              <Link href={data?.previewLink} passHref>
                <a target="_blank">
                  <S.PreviewTitle>{data?.previewTitle}</S.PreviewTitle>
                </a>
              </Link>
              {data?.previewDescription && (
                <Link href={data?.previewLink} passHref>
                  <a target="_blank">
                    <S.PreviewDesc>{data?.previewDescription}</S.PreviewDesc>
                  </a>
                </Link>
              )}
            </S.PreviewDescPanel>
          </S.ExternalPreview>
        )}
        {displayComments && (
          <Comments
            postId={data?.id}
            count={data?.comments}
            compactMode
            commentsStyle={commentsStyle}
          />
        )}
      </S.PostInfo>
    </S.Container>
  )
}

FeedPost.defaultProps = defaultProps
FeedPost.propTypes = propTypes

export default FeedPost
