import api from 'shared/api'

export const like = async ({ accessToken, postId }) => {
  await api.post(`/posts/like/${postId}`, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const unlike = async ({ accessToken, postId }) => {
  await api.delete(`/posts/like/${postId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const deletePost = async ({ accessToken, postId }) => {
  await api.delete(`/posts/${postId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}
