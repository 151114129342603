import React, { useState, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Linkify from 'linkify-react'
import { ShareBar, Modal, FeedBadge } from 'components/elements'
import { withSizes } from 'components/helpers'
import { useSession } from 'context/Session'
import Zoom from 'react-medium-image-zoom'

import LikeButton from './LikeButton'

import { doLoadPost, deletePost } from '../modules/datasource'

import * as S from './PostModal.styles'
import { Author } from './Comments.styles'
import Comments from './Comments'

const defaultProps = {}
const propTypes = {}

const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL

const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/

const getLink = link => {
  if (!link) return null

  const match = link.match(regExp)

  if (match && match[2].length == 11) {
    return match[2]
  } else {
    return null
  }
}

export const PostModal = ({
  data: initialValues,
  onClose,
  onDelete,
  postId,
  isMobile,
}) => {
  const router = useRouter()
  const [, setIsFetching] = useState(true)
  const [data, setPostData] = useState(initialValues)
  const { user: sessionUser, accessToken } = useSession()
  const [isOpen, setOpen] = useState(null)
  const [showComments, setShowComments] = useState(true)

  useEffect(async () => {
    if (Number(postId) !== Number(initialValues?.id)) {
      setIsFetching(true)
      const { data } = await doLoadPost(postId)
      setIsFetching(false)
      setPostData(data)
    }
  }, [postId])

  const shareLink = SITE_URL + router.asPath

  const commentDesc = useMemo(() => {
    if (isMobile) {
      return data?.comments > 0 ? data?.comments : ''
    }
    return `${data?.comments || 0} comment${data?.comments > 1 ? 's' : ''}`
  }, [data?.comments, isMobile])

  const isMine = data?.profile?.username === sessionUser?.profile?.username

  const hasPreview = data?.previewLink && data?.previewTitle

  const isYoutube = getLink(data?.previewLink)

  const commentsConfig = {
    showComments,
    setShowComments,
  }

  return (
    <>
      <S.DisableBodyOverflow />
      <S.Container>
        <S.Wrapper>
          <S.Header>
            <S.Content style={{ paddingBottom: 0 }}>
              <S.HeaderContent>
                <S.Close onClick={onClose} />
                <FeedBadge type="post" size="large" />
              </S.HeaderContent>
            </S.Content>
          </S.Header>
          <S.Content>
            {data?.image && (
              <Zoom>
                <S.PostImg
                  // src={getCloudFlareImgVariant(data?.image, {
                  //   default: 'articleImageDefault',
                  // })}
                  src={data?.image}
                />
              </Zoom>
            )}
            <S.Description style={{ paddingTop: data?.image ? 0 : 10 }}>
              <Linkify options={{ target: '_blank' }}>{data?.text}</Linkify>
            </S.Description>
            {hasPreview && !isYoutube && (
              <S.ExternalPreview hasimg={!data?.image && data?.previewImage}>
                {!data?.image && data?.previewImage && (
                  <Link href={data?.previewLink} passHref>
                    <a style={{ display: 'flex' }} target="_blank">
                      <S.PreviewImg>
                        <S.StyledImg src={data?.previewImage} />
                      </S.PreviewImg>
                    </a>
                  </Link>
                )}
                <S.PreviewDescPanel>
                  <Link href={data?.previewLink} passHref>
                    <a target="_blank">
                      <S.PreviewTitle>{data?.previewTitle}</S.PreviewTitle>
                    </a>
                  </Link>
                  {data?.previewDescription && (
                    <Link href={data?.previewLink} passHref>
                      <a target="_blank">
                        <S.PreviewDesc>
                          {data?.previewDescription}
                        </S.PreviewDesc>
                      </a>
                    </Link>
                  )}
                </S.PreviewDescPanel>
              </S.ExternalPreview>
            )}
            {isYoutube && (
              <S.StyledYouTube videoId={isYoutube} id={isYoutube} />
            )}
            <S.Postdate>
              {data?.profile && (
                <Author profile={data?.profile} createdAt={data?.publishedAt} />
              )}
            </S.Postdate>

            <S.ShareWrapper>
              <ShareBar
                disableMenu={!sessionUser}
                id={postId}
                url={shareLink}
                quote={data?.text}
                actions={
                  isMine
                    ? [
                        {
                          label: 'Delete',
                          onClick: () => setOpen('post'),
                        },
                      ]
                    : []
                }
              />
              <S.OptionsContent>
                <LikeButton
                  style={{ marginRight: 15 }}
                  data={data}
                  user={sessionUser}
                  isLiked={initialValues?.isLiked}
                />
                {sessionUser ? (
                  <Link href="#comments" passHref>
                    <S.CommentsCount>
                      <S.CommentIcon />
                      {commentDesc}
                    </S.CommentsCount>
                  </Link>
                ) : (
                  <S.CommentsCount disabled>
                    <S.CommentIcon />
                    {commentDesc}
                  </S.CommentsCount>
                )}
              </S.OptionsContent>
            </S.ShareWrapper>
          </S.Content>
          <S.CommentsSection
            id="comments"
            style={{
              minHeight: 'unset',
              scrollMarginTop: '73px',
              paddingBottom: 50,
            }}
          >
            <Comments
              initialState={initialValues?.commentsData}
              reference={data?.id}
              count={data?.comments}
              {...commentsConfig}
            />
          </S.CommentsSection>
        </S.Wrapper>
        <Modal
          isOpen={!!isOpen}
          close={() => setOpen(null)}
          closeOnOutside={false}
        >
          <S.DeleteContent>
            <S.DeleteTitle>
              Are you sure you want to delete
              <br /> your {isOpen}?
            </S.DeleteTitle>
            <S.DeleteButton
              type="button"
              onClick={async () => {
                await deletePost({ accessToken, postId })
                onDelete && onDelete(postId)
                onClose()
              }}
            >
              Yes, delete this {isOpen}
            </S.DeleteButton>
            <S.DeleteButton
              type="button"
              variant="text"
              onClick={() => setOpen(null)}
            >
              Cancel
            </S.DeleteButton>
          </S.DeleteContent>
        </Modal>
      </S.Container>
    </>
  )
}

PostModal.defaultProps = defaultProps
PostModal.propTypes = propTypes

export default withSizes(PostModal)
