import * as R from 'ramda'
import React, { useState } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import Linkify from 'linkify-react'
import { GrFormClose } from 'react-icons/gr'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useSession } from 'context/Session'
import { Input, Button, Loading, Modal } from 'components/elements'

import { loadComments, deleteComment, editComment } from '../modules/datasource'

export const Container = styled.div`
  width: 100%;
  max-width: 704px;
  margin: 34px auto;
  background: ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  flex-direction: column;
  textarea {
    min-width: 100%;
  }
  ${({ theme }) => theme.media.tablet} {
    padding: 0 32px;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 0 16px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  ${({ theme }) => theme.font.h3}
  margin-bottom: 24px;
`

const PublishContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .MuiInputBase-root {
    background-color: white;
  }
`
const PublishFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
`

export const PublishOption = ({
  label = 'What do you think?',
  publishLabel = 'Publish',
  anyFetching,
  setAnyFetching,
  onPublish,
  parentId,
  defaultValue = '',
  onCancel,
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [text, setText] = useState(defaultValue)

  const publish = () => {
    setAnyFetching(true)
    setIsFetching(true)
    try {
      onPublish({ text, parentId }).then(() => {
        enqueueSnackbar('Comment posted', {
          variant: 'success',
        })
        setText('')
        setIsFetching(false)
        setAnyFetching(false)
      })
    } catch (e) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      })
      setAnyFetching(false)
      setIsFetching(false)
    }
  }

  return (
    <PublishContainer>
      <Input
        name="bio"
        label={label}
        multiline
        minRows={3}
        inputProps={{
          maxLength: 3000,
        }}
        disabled={anyFetching || isFetching}
        value={text}
        onChange={e => setText(e?.target?.value)}
        helperText={`${text.length}/3000`}
      />
      <PublishFooter>
        {onCancel && (
          <Button
            disabled={anyFetching || isFetching || !text}
            type="button"
            variant="text"
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          disabled={anyFetching || isFetching || !text}
          loading={isFetching}
          type="button"
          variant="text"
          onClick={publish}
        >
          {publishLabel}
        </Button>
      </PublishFooter>
    </PublishContainer>
  )
}

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledComment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0 32px;
  & + & {
    border-top: solid 1px ${({ theme }) => theme.colors.grey2};
  }
`
const StyledReply = styled(StyledComment)`
  padding: 0;
`

const AuthorContainer = styled.div`
  display: flex;
  margin: 0 0 16px;
  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`
const AuthorPicPlaceHolder = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 12px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`
const AuthorPic = styled.img`
  height: 48px;
  width: 48px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-right: 12px;
  background: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 12px;
  }
`
const AuthorContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 12px;
  }
`
const AuthorName = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`
const AuthorDesc = styled.div`
  color: ${({ theme }) => theme.colors.grey};
`
const InReviewBadge = styled.span`
  ${({ theme }) => theme.font.body2};
  background: ${({ theme }) => theme.colors.grey2};
  color: ${({ theme }) => theme.colors.grey};
  border-radius: 8px;
  margin-left: 10px;
  padding: 1px 5px;
  display: flex;
  width: 70px;
  align-items: center;
  justify-content: center;
`
const EditedTag = styled.span`
  cursor: pointer;
`
const HistoryList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 18px;
  padding: 0 15px 30px;
`
const HistoryItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
`
const HistoryItemDate = styled.div`
  ${({ theme }) => theme.font.label};
  color: ${({ theme }) => theme.colors.grey};
`
const HistoryItemContent = styled.div`
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.grey2};
`
export const HystoryModal = styled(Modal)`
  max-width: 500px !important;
  max-height: 50vh;
`

const Author = ({ profile, createdAt, inReview, history }) => {
  const ref = `/p/${profile.username}`
  const [isModal, setModal] = useState(false)

  return (
    <AuthorContainer key={`author-${profile?.username}`}>
      {profile?.username ? (
        <Link href={ref}>
          <a>
            {profile?.profileImage ? (
              <AuthorPic src={profile?.profileImage} />
            ) : (
              <AuthorPicPlaceHolder>
                {R.head(profile?.username || 'U')}
              </AuthorPicPlaceHolder>
            )}
          </a>
        </Link>
      ) : (
        <AuthorPicPlaceHolder>
          {R.head(profile?.username || 'U')}
        </AuthorPicPlaceHolder>
      )}
      <AuthorContent>
        <AuthorName>
          <Link href={ref} style={{ width: 'fit-content' }}>
            <a>{`${profile?.username || ''}`}</a>
          </Link>
          {inReview && <InReviewBadge>In review</InReviewBadge>}
        </AuthorName>
        <AuthorDesc>
          {createdAt && format(new Date(createdAt), 'MMM. d, yyyy, h:mm aaaa')}
          <EditedTag onClick={() => setModal(true)}>
            {!R.isEmpty(history || []) && ' (Edited)'}
          </EditedTag>
        </AuthorDesc>
      </AuthorContent>
      <HystoryModal
        isOpen={isModal}
        close={() => setModal(false)}
        closeOnOutside={true}
        title="History"
      >
        <HistoryList>
          {(history || [])?.map(({ createdAt, text }) => {
            return (
              <HistoryItem key={`history-${createdAt}`}>
                <HistoryItemDate>
                  {format(new Date(createdAt), 'MMM. d, yyyy, h:mm aaaa')}
                </HistoryItemDate>
                <HistoryItemContent>{text}</HistoryItemContent>
              </HistoryItem>
            )
          })}
        </HistoryList>
      </HystoryModal>
    </AuthorContainer>
  )
}
const StyledText = styled.div`
  margin-bottom: 16px;
  white-space: pre-wrap;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`
const CommentActions = styled.div`
  display: flex;
  align-items: center;
`
const CommentAction = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.font.bold};
  cursor: pointer;
  user-select: none;
  & + & {
    margin-left: 10px;
  }
`
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`
const ExtraContainer = styled.div`
  border-left: solid 4px ${({ theme }) => theme.colors.grey2};
  padding-left: 16px;
  margin-left: 30px;
  margin-top: 24px;
  width: 100%;
  ${({ theme }) => theme.media.phone} {
    margin-left: 14px;
  }
`
const CloseIcon = styled(GrFormClose)`
  path {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`

const Reply = props => {
  const {
    accessToken,
    reference,
    user,
    rply,
    editId,
    setEditId,
    setDeleteId,
    setShowDelete,
    publishConfig,
    doComment,
    setListState: setCommentsState,
    setDeleteConfig,
  } = props

  const parentId = rply?.id
  const [crrPage, setCrrPage] = useState(1)
  const [showReplies, setShowReplies] = useState(false)
  const [crrListState, setListState] = useState({})
  const [showReply, setShowReply] = useState(false)
  const [totalReplies, setTotalReplies] = useState(rply?.replies || 0)

  const { isFetching } = useQuery(
    [
      'article-replyes',
      { accessToken, reference, showReplies, parentId, crrPage },
    ],
    () =>
      showReplies
        ? loadComments({
            reference,
            accessToken,
            parentId,
            page: crrPage,
          })
        : { list: [], total: 0 },
    {
      fetchPolicy: 'no-cache',
      onSuccess: data =>
        setListState(({ list = [] }) => ({
          ...data,
          list: R.uniqBy(R.prop('id'), R.concat(list, data?.list)),
        })),
    }
  )

  const doReply = ({ text, parentId }) => {
    return doComment({ reference, text, parentId }).then(({ data }) => {
      setListState(({ list = [], ...crrState }) => ({
        ...crrState,
        list: R.concat([data], list),
      }))
      setTotalReplies(R.add(1))
    })
  }

  const doEditComment = ({ text, parentId }) => {
    return editComment({ text, id: editId, accessToken }).then(({ data }) => {
      if (parentId) {
        setListState(({ list = [], ...crrState }) => {
          const index = R.findIndex(R.propEq('id', editId), list)

          return {
            ...crrState,
            list: R.update(index, data, list),
          }
        })
      } else {
        setCommentsState(({ list = [], ...crrState }) => {
          const index = R.findIndex(R.propEq('id', editId), list)

          return {
            ...crrState,
            list: R.update(index, data, list),
          }
        })
      }
      setEditId(null)
    })
  }

  const { list = [], total } = crrListState

  return (
    <ExtraContainer>
      <StyledReply>
        <Author
          profile={rply?.profile}
          createdAt={rply?.createdAt}
          inReview={rply?.inReview}
          history={rply?.history}
        />
        <StyledText>
          <Linkify options={{ target: '_blank' }}>{rply?.text}</Linkify>
        </StyledText>
        <CommentActions>
          {totalReplies > 0 && (
            <CommentAction onClick={() => setShowReplies(true)}>
              Show more ({totalReplies})
            </CommentAction>
          )}
          <CommentAction onClick={() => setShowReply(s => !s)}>
            Reply {showReply && <CloseIcon />}
          </CommentAction>
          {rply?.profile?.username === user?.profile?.username && (
            <>
              <CommentAction
                onClick={() => {
                  setEditId(rply.id)
                }}
              >
                Edit
              </CommentAction>
              <CommentAction
                onClick={() => {
                  setDeleteConfig({})
                  setDeleteId(rply.id)
                  setShowDelete(true)
                }}
              >
                Delete
              </CommentAction>
            </>
          )}
        </CommentActions>
        {showReply && (
          <ExtraContainer>
            <PublishOption
              onPublish={doReply}
              {...publishConfig}
              parentId={rply?.id}
            />
          </ExtraContainer>
        )}
        {(showReplies || list?.length > 0) &&
          list.map(rply => {
            if (rply.id === editId) {
              return (
                <ExtraContainer>
                  <PublishOption
                    label="Edit comment"
                    publishLabel="Save"
                    onPublish={doEditComment}
                    defaultValue={rply?.text}
                    onCancel={() => setEditId(null)}
                    autoFocus
                    parentId={rply?.id}
                    {...publishConfig}
                  />
                </ExtraContainer>
              )
            }
            return (
              <ExtraContainer
                key={`${rply?.profile?.username}-${rply?.createdAt}`}
              >
                <StyledReply>
                  <Author
                    profile={rply?.profile}
                    createdAt={rply?.createdAt}
                    inReview={rply?.inReview}
                    history={rply?.history}
                  />
                  <StyledText>
                    <Linkify options={{ target: '_blank' }}>
                      {rply?.text}
                    </Linkify>
                  </StyledText>
                  {rply?.profile?.username === user?.profile?.username && (
                    <CommentActions>
                      <CommentAction
                        onClick={() => {
                          setEditId(rply.id)
                        }}
                      >
                        Edit
                      </CommentAction>
                      <CommentAction
                        onClick={() => {
                          setDeleteConfig({
                            setCrrRepliesList: setListState,
                            setCrrParentList: setCommentsState,
                            setCrrTotalReplies: setTotalReplies,
                          })
                          setDeleteId(rply.id)
                          setShowDelete(true)
                        }}
                      >
                        Delete
                      </CommentAction>
                    </CommentActions>
                  )}
                </StyledReply>
              </ExtraContainer>
            )
          })}
        {!isFetching && list?.length < total && (
          <ShowMore onClick={() => setCrrPage(p => p + 1)}>
            Show more replies
          </ShowMore>
        )}
        {isFetching && (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        )}
      </StyledReply>
    </ExtraContainer>
  )
}

const Comment = ({
  reference,
  data,
  setListState: setCommentsState,
  setCount,
  doComment,
  anyFetching,
  setAnyFetching,
}) => {
  const { createdAt, history, id, inReview, profile, replies, text } = data

  const [isDeleting, setIsDeleting] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [showReplies, setShowReplies] = useState(false)
  const [showReply, setShowReply] = useState(false)
  const { user, accessToken } = useSession()
  const [crrListState, setListState] = useState({})
  const [crrPage, setCrrPage] = useState(1)
  const [totalReplies, setTotalReplies] = useState(replies || 0)
  const [editId, setEditId] = useState(null)

  const [deleteConfig, setDeleteConfig] = useState({})

  const handleClose = () => !isDeleting && setShowDelete(false)

  const { isFetching } = useQuery(
    ['article-replyes', { accessToken, reference, showReplies, id, crrPage }],
    () =>
      showReplies
        ? loadComments({
            reference,
            accessToken,
            parentId: id,
            page: crrPage,
          })
        : { list: [], total: 0 },
    {
      fetchPolicy: 'no-cache',
      onSuccess: data =>
        setListState(({ list = [] }) => ({
          ...data,
          list: R.uniqBy(R.prop('id'), R.concat(list, data?.list)),
        })),
    }
  )

  const config = {
    anyFetching,
    setAnyFetching,
    setListState,
    setCount,
    setTotalReplies,
  }
  const { list = [], total } = crrListState

  const onDelete = async () => {
    const {
      setCrrRepliesList = setListState,
      setCrrParentList = setCommentsState,
      setCrrTotalReplies = setTotalReplies,
    } = deleteConfig || {}

    if (!isDeleting) {
      const commentId = deleteId || id
      setIsDeleting(true)
      try {
        await deleteComment({ accessToken, id: commentId })
        if (deleteId) {
          setCrrRepliesList(state => ({
            total: state?.total - 1,
            list: R.reject(R.propEq('id', commentId), state?.list),
          }))
          setCrrTotalReplies(R.subtract(R.__, 1))
        } else {
          setCrrParentList(state => ({
            total: state?.total - 1,
            list: R.reject(R.propEq('id', commentId), state?.list),
          }))
        }
        setCount(c => c - 1)
        setIsDeleting(false)
        setDeleteId(null)
        handleClose(true)
        setDeleteConfig({})
      } catch (error) {
        setIsDeleting(false)
        setDeleteId(null)
        handleClose(true)
      }
    }
  }

  const doReply = ({ text, parentId }) => {
    return doComment({ reference, text, parentId }).then(({ data }) => {
      setListState(({ list = [], ...crrState }) => ({
        ...crrState,
        list: R.concat([data], list),
      }))
      setTotalReplies(R.add(1))
    })
  }

  const doEditComment = ({ text, parentId }) => {
    return editComment({ text, id: editId, accessToken }).then(({ data }) => {
      if (parentId) {
        setListState(({ list = [], ...crrState }) => {
          const index = R.findIndex(R.propEq('id', editId), list)

          return {
            ...crrState,
            list: R.update(index, data, list),
          }
        })
      } else {
        setCommentsState(({ list = [], ...crrState }) => {
          const index = R.findIndex(R.propEq('id', editId), list)

          return {
            ...crrState,
            list: R.update(index, data, list),
          }
        })
      }
      setEditId(null)
    })
  }

  return (
    <StyledComment>
      <Author
        profile={profile}
        createdAt={createdAt}
        inReview={inReview}
        history={history}
      />
      {id === editId ? (
        <PublishOption
          label="Edit comment"
          publishLabel="Save"
          onPublish={doEditComment}
          defaultValue={text}
          onCancel={() => setEditId(null)}
          autoFocus
          {...config}
        />
      ) : (
        <StyledText>
          <Linkify options={{ target: '_blank' }}>{text}</Linkify>
        </StyledText>
      )}
      {id !== editId && (
        <CommentActions>
          {totalReplies > 0 && (
            <CommentAction onClick={() => setShowReplies(true)}>
              Show more ({totalReplies})
            </CommentAction>
          )}
          <CommentAction onClick={() => setShowReply(s => !s)}>
            Reply {showReply && <CloseIcon />}
          </CommentAction>
          {profile?.username === user?.profile?.username && (
            <>
              <CommentAction onClick={() => setEditId(id)}>Edit</CommentAction>
              <CommentAction onClick={() => setShowDelete(true)}>
                Delete
              </CommentAction>
            </>
          )}
        </CommentActions>
      )}
      {showReply && (
        <ExtraContainer>
          <PublishOption onPublish={doReply} {...config} parentId={id} />
        </ExtraContainer>
      )}
      {(showReplies || list?.length > 0) &&
        list.map(rply => {
          if (rply.id === editId) {
            return (
              <ExtraContainer>
                <PublishOption
                  label="Edit comment"
                  publishLabel="Save"
                  onPublish={doEditComment}
                  defaultValue={rply?.text}
                  onCancel={() => setEditId(null)}
                  autoFocus
                  parentId={rply?.id}
                  {...config}
                />
              </ExtraContainer>
            )
          }

          const replyConfig = {
            accessToken,
            reference,
            user,
            rply,
            editId,
            setEditId,
            setDeleteId,
            setShowDelete,
            publishConfig: config,
            doComment,
            setListState,
            setDeleteConfig,
          }

          return (
            <Reply
              key={`${rply?.profile?.username}-${rply?.createdAt}`}
              {...replyConfig}
            />
          )
        })}
      {!isFetching && list?.length < total && (
        <ShowMore onClick={() => setCrrPage(p => p + 1)}>
          Show more replies
        </ShowMore>
      )}
      {isFetching && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
      <Dialog
        open={showDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete comment</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={isDeleting} variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            loading={isDeleting}
            color="error"
            variant="text"
            onClick={onDelete}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </StyledComment>
  )
}

export const ShowMore = styled.div`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.font.bold};
  cursor: pointer;
  user-select: none;
  width: fit-content;
  margin: 0 auto;
  & + & {
    margin-left: 10px;
  }
`

export const CommentList = ({ list, ...rest }) => {
  return (
    <StyledList>
      {list.map(item => (
        <Comment
          {...rest}
          data={item}
          key={`${item?.profile?.username}-${item?.createdAt}`}
        />
      ))}
    </StyledList>
  )
}

const PlaceHolderContainer = styled(Container)`
  align-items: center;
`
const CommentsButton = styled(Button)``

export const CommentPlaceHolder = ({ showComments, count }) => (
  <PlaceHolderContainer>
    <CommentsButton color="black" onClick={showComments}>
      View Comments ({count})
    </CommentsButton>
  </PlaceHolderContainer>
)
